<template>
  <div id="flightLog" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4">
        <div class="N-Page T-size-36">Flight Log</div>
      </div>
    </div>
    <div id="calendarDATA" class="wrap-Main">
      <div class="box-S2">
        <CompListFlight ref="CompListFlight" :DataDateFromCalendar="datadatefromcsnder" :Service="'flightlogselect'"></CompListFlight>
      </div>
      <div class="box-S2">
        <calendar :Service="'flightlogselect'" @DataCalender="BindingDataFromCalendar"></calendar>
      </div>
    </div>

    <div id="partBack" class="wrap-Main">
      <div class="line"></div>
      <div class="box-S4">
        <v-btn text class="ColorTheme-S white--text theme-btn" @click="Back()">
          <span class="I-back"></span>
          <span>Back</span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import calendar from "../components/main/Calendar";
import CompListFlight from "../components/main/CompList";
import { startOfDay, endOfDay, format } from "date-fns";
var today = new Date()
export default {
  data: () => ({
    datadatefromcalender: new Date().toISOString().substr(0, 10)
  }),
  components: {
    calendar,
    CompListFlight
  },
  async mounted() {
      this.BindingDataFromCalendar(format(today, "yyyy-MM-dd"));
  },
  methods: {
    Back() {
      this.$router.go(-1);
    },
   async BindingDataFromCalendar(dataDate) {
      //console.log(dataDate)
      this.datadatefromcalender = dataDate;
      await this.$refs.CompListFlight.renderListFlight(dataDate);
    },
   
  }
};
</script>
<style>
</style>